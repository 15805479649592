
export default function useFormatedDate() {
    function formatMonth(month: number) {
      if (month.toString().length === 1) {
        return `0${month}`;
      } else {
        return month;
      }
    }

    // format date
    function formatDates(
      arrival: boolean,
      year: string,
      month: number,
      day: number
    ) {
      if (arrival) {
        const arrivalDate = `${year}-${formatMonth(month)}-${formatMonth(day)}`;

        return arrivalDate;
      } else {
        const departureDate = `${year}-${formatMonth(month)}-${formatMonth(
          day
        )}`;

        return departureDate;
      }
    }

    function lastday(y: any, m: any) {
      return new Date(y, m, 0).getDate();
    };

    function getToday() {
    const today = new Date();
    return `${today.getFullYear()}-${
      formatMonth(today.getMonth() + 1)
    }-${today.getDate()}`;
  };

    return { formatMonth, formatDates, lastday, getToday };
}