// import {  } from "react-bootstrap";
import React from "react";
import { Col, Row, Card, Alert } from "react-bootstrap";
import { BookingsContext } from "../context/booking.context";

const user = JSON.parse(sessionStorage.getItem("ubold_user")!);

export default class ErrorBoundary extends React.Component<
  {},
  {
    hasError: boolean;
    msg: string;
  }
> {
  // context
  static contextType = BookingsContext;

  constructor(props: any) {
    super(props);
    this.state = { hasError: false, msg: "" };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, msg: `${error}` };
  }

  sendError = (error: any) => {
    try {
      //  window.location.href.includes("localhost")
      //   ? `https://test.airagents.co.za/api/v2/errorlogs`

      // URL
      const { BASE_URL, API_KEY, API_VERSION } = this.context;

      // Creates new date
      const date = new Date();

      // Holds data about the error
      const errorDetails = {
        error,
        user: `${user.name} ${user.surname}`,
        user_email: user.email,
        date: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${
          date.toString().split(" ")[4]
        }`,
        url: window.location.href,
      };

      // sends error to be saved on database if is not on dev mode
      !window.location.href.includes("localhost") &&
        fetch(`${BASE_URL}${API_VERSION}/errorlogs`, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Key: `31e64424a3d86408c4c062abcb698571`,
          },
          body: JSON.stringify(errorDetails),
        });
    } catch (error) {
      throw new Error(`${error}`);
    }
  };

  componentDidCatch(error: any, errorInfo: any) {
    // Send error to database
    this.sendError(`${error.message}`);

    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <React.Fragment>
          <div className="account-pages mt-5 mb-5">
            <div className="container">
              <Row className="justify-content-center">
                <Col lg={4}>
                  <Card>
                    <Card.Body className="p-4">
                      <div className="error-ghost text-center">
                        <h2 className="text-primary">
                          <i className="fe-x"></i>
                        </h2>
                      </div>

                      <div className="text-center">
                        <h3 className="mt-4">Something went wrong</h3>
                        <p className="mb-0">
                          This may happen because your request was not
                          successful or there's a component that did not
                          rendered properly. Please refresh the page or contact
                          your developer if you continue to see this.
                          <br /> Copy or screenshot the error below for your
                          developer reference
                        </p>
                        <Alert variant="danger" style={{ marginTop: "20px" }}>
                          {this.state.msg || "the error couldn't be generated"}
                        </Alert>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </React.Fragment>
      );
    }

    return this.props.children;
  }
}
